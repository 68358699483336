.custom-loader {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 99;

  .ant-spin {
    color: #0e136d;
    font-size: 15;
    font-weight: 500;
  }

  .ant-spin-dot-item {
    background: #0e136d;
  }
}
