.login-right-section {
  background: #0e136d;
  padding: 10%;
  height: 100vh;

  img {
    width: 100%;
    height: 265px;
    object-fit: contain;
    opacity: 1;
    margin-bottom: 55px;
  }

  .main-tagline {
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
  }

  .sub-tagline {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
}

.login-left-section {
  display: flex !important;
  justify-content: center;
  align-items: center;

  .verification-code-container {
    display: flex;
    width: 100%;

    .one-digit-input {
      flex: 1;
      border: 1px solid #e2e8f0;
      border-radius: 0;
      text-align: center;
      width: 55px !important;
      height: 50px;

      &:first-child {
        border-left: 1px solid #e2e8f0;
      }

      &:focus {
        outline: none !important;
        border: 1px solid #e2e8f0 !important;
        border-radius: 0 !important;
      }
    }
  }

  .login-card {
    position: relative;
    width: 380px;
    border-radius: 14px;
    -webkit-box-shadow: 1px 1px 19px -2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 1px 1px 19px -2px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 19px -2px rgba(0, 0, 0, 0.25);

    .reset-password-title {
      font-weight: bolder;
      font-size: 24px;
      margin-bottom: 30px;
      width: 100%;
      text-align: center;
    }

    .description {
      font-size: 13px;
      margin-bottom: 30px;
    }

    .logo-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 20px;

      .logo {
        width: 150px;
      }
    }

    .password-field {
      margin-bottom: 5px;
    }

    .forgot-password-btn-container {
      display: flex;
      justify-content: flex-end;
      margin: 0;

      .ant-btn-link {
        color: #2e404f;
        font-weight: 500;
      }
    }

    .ant-btn-lg {
      padding: 4px 30px !important;
    }
  }
}
